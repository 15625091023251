.btnbag_hydration{
    width: 100%;
    max-width: 30%;
    margin: 20px auto;
}
.btnbag_hydration button{ opacity: 1 !important; visibility:visible !important; width: 100%; margin: auto; background: #b8202e;
    border-radius: 5px; padding: 3px; cursor: pointer; font-size: 11px;}

.kumkumadi_banner{ width: 100%; display: inline-block;}
.kumkumadi_banner iframe{ width: 100%;}
.kumkumadi_main{width: 100%;background-image: url(/images/kumkumadi_day_bg_desktop.jpg);display:block;margin-bottom: -60px; }
.kumkumadi_sec h2{ position: relative; font-size:54px; color: #9058a2; font-weight: 600; letter-spacing: 0.25px; text-align: center;    padding-bottom: 40px;
    margin-bottom: 40px;}
.kumkumadi_sec:before{ display: none; content: "";width: 164px; height:260px; background-image: url(/images/kumkumadi_sec_img.png); position: absolute; left: 0;}
.kumkumadi_sec p{ line-height: 1.4; font-size:24px; text-align: center; color: #9058a2; font-weight:500;letter-spacing: 0.25px;font-family: Cormorant Garamond;}
.kumkumadi_sec{ padding: 0 60px; padding-top: 40px; padding-bottom: 0px;width: 100%; max-width: 1920px;    margin: 0 auto; }
    .kumkumadi_sec h2::before{content: ""; height: 2px; width: 350px; background-color: #9058a2; position: absolute; bottom: 0;left: 50%;
        transform: translate(-50%, 0px);}
        .kumkumadi_sec p span{ display: block;}
        .kumkumadi_sec1{  width: 100%; max-width: 1800px;    margin: 0 auto;}
        .kumkumadi_sec1 span{ display: block;}
        .kumkumadi_sec2{ padding-top: 50px;  width: 100%; max-width: 1800px;    margin: 0 auto; padding-bottom: 60px;}
        .kumkumadi_sec2 p{line-height: 1.4; font-size:24px; color: #9058a2; font-weight:500;letter-spacing: 0.25px;font-family: Cormorant Garamond;}
        .kumkumadi_sec2 p strong, .kumkumadi_sec2 p span{ display: block;color: #9058a2;}
.kumkumadi_sec3{width: 100%;background-image: url(/images/kumkumadi_sec_img4.png);display:block; padding:80px 60px;background-size: cover;background-position: center; }
.kumkumadi_sec3_main{width: 100%; max-width: 1800px;    margin: 0 auto;    background: rgb(255 255 255 / 50%);
    padding: 60px; text-align: center;}
    .kumkumadi_sec3_main h2{font-size:46px; color: #9058a2; font-weight: 600; letter-spacing: 0.25px; text-align: center;    padding-bottom:15px; text-transform: uppercase;}
    .kumkumadi_sec3_main h2 span{ display: block;font-size:62px;line-height: 1;}
    .kumkumadi_sec3_main p{ color: #5f3920; font-size: 18px; margin-bottom: 0;}
    .kumkumadi_sec3_main p span{ display: block;}
    .kumkumadi_sec3_main p strong{color: #5f3920;}
    .kumkumadi_sec3_main abbr{ display: block; margin-top: 30px;}
    .kumkumadi_sec4{width: 100%; max-width: 1800px;    margin: 0 auto; padding-top:60px;}
    .kumkumadi_sec4 h2{ font-size:54px; color: #9058a2; font-weight: 600; letter-spacing: 0.25px; text-align: center;
        margin-bottom: 30px;text-transform: uppercase;}
        .kumkumadi_main video{ display: block;}
        .btnbag_kumkumadi{ position: fixed !important;    bottom: 10px; right: 15%; z-index: 99; min-width: 260px;}
        .btnbag_kumkumadi button{ opacity: 1 !important; visibility:visible !important;}
        .kumkumadi_sec1 h2, .kumkumadi_sec2 h2, .kumkumadi_sec5 h2{font-size:54px; color: #9058a2; font-weight: 600; letter-spacing: 0.25px; text-align: center;
            margin-bottom: 20px; text-transform: uppercase;}
            .kumkumadi_sec1 h2{ line-height: 1; margin-bottom: 30px;}
            .kumkumadi_sec5 h2 abbr{ font-family: 'Open Sans';display: block; color: #603a21; font-size: 34px; text-transform: initial;}
            .kumkumadi_sec2 ul li b{ font-style: normal;}
            .kumkumadi_sec2 ul li abbr{ display: block;padding-top: 18px;
                padding-bottom: 13px;}
            .kumkumadi_sec2 ul li b, .kumkumadi_sec2 ul li span{ display: block;}
            .kumkumadi_sec2 ul li{ line-height: 1.3; font-size: 24px; font-style: italic; color: #9058a2; font-weight: 300; text-align: center; }
        @media (max-width: 1600px){
            .kumkumadi_sec2 p{ font-size: 22px;}
            .kumkumadi_sec2 ul li{ font-size: 20px;}
            .btnbag_kumkumadi{right: 10%;}
        }
        @media (max-width: 767px){
            .btnbag_hydration{
                width: 100%;
                max-width: 55%;
                margin: 20px auto;
            }
            .kumkumadi_sec1 span div{ display: block !important;}
            .kumkumadi_sec2 ul{ padding-top: 30px;}
            .kumkumadi_sec2 ul li b{ font-style: normal;}
            .kumkumadi_sec2 ul li abbr{ display: block;padding-top: 18px;
                padding-bottom: 13px;}
            .kumkumadi_sec2 ul li b, .kumkumadi_sec2 ul li span{ display: block;}
            .kumkumadi_sec2 ul li{ line-height: 1.3; font-size: 16px; font-style: italic; color: #9058a2; font-weight: 300; text-align: center; }
            .kumkumadi_sec1 h2, .kumkumadi_sec2 h2{ font-size: 32px; margin-bottom: 0;}
            .kumkumadi_sec5 h2{font-size: 32px;margin-bottom: 12px;}
            .kumkumadi_sec5 h2 abbr{ font-family: 'Open Sans';display: block; color: #603a21; font-size: 26px; text-transform: initial;}
            .kumkumadi_sec2 h2{ margin-bottom: 15px;line-height: 1.1;}
            .kumkumadi_banner{ display: block;}
            .kumkumadi_sec{ padding: 0 20px;padding-top:40px;}
            .kumkumadi_sec h2{      line-height: 1.2;  font-size: 32px;    padding-bottom: 12px; margin-bottom: 0px;    padding-top:40px;}
            .kumkumadi_sec p span{ display: initial;}
            .kumkumadi_sec p{ font-size: 20px;}
            .kumkumadi_sec h2:before{ display: none;}
            .kumkumadi_sec:before{ display: none; width: 58px; height:85px; background-image: url(/images/kumkumadi-strip1.png);}
            .kumkumadi_sec_motif{ position: relative; width: 100%; display: block; min-height: 96px;}
            .kumkumadi_sec_motif span{ position: absolute; right:-20px;}
            .kumkumadi_sec_motif:before{content: ""; height: 2px; width:82%; background-color: #9058a2; position: absolute;    left: -20px;
                top: 50px;}
                .kumkumadi_sec1 span{padding-bottom:30px;    margin: 0 auto; display: table;}
                .kumkumadi_sec_motif1{ position: relative; width: 100%; display: block; min-height: 100px; margin-bottom: 30px;}
                .kumkumadi_sec_motif1 span{ position: absolute; left:0px;}
                .kumkumadi_sec_motif1:before{content: ""; height: 2px; width:76%; background-color: #9058a2; position: absolute; right:0px;
                    top: 50px;}
                    .kumkumadi_sec2{  padding-bottom: 40px; padding-top: 0;}
                    .kumkumadi_sec3{ padding: 0; background: none;}
                    .kumkumadi_sec3 span{ display: table; margin: 0 auto;}
                    .kumkumadi_sec4{ padding-top:30px;display:table;}
                    .kumkumadi_sec4 span{ float: right;}
                    .kumkumadi_sec4 span div{ display: block !important;}
                    .kumkumadi_sec4 h2{ font-size:34px; margin-bottom: 20px; }
                    .kumkumadi_sec4 .kumkumadi_sec_motif span{ right: 0;}
                    .kumkumadi_sec4 .kumkumadi_sec_motif:before{ display:none;}
                    .kumkumadi_sec5 .kumkumadi_sec_motif1:before{ display: none;}
                    .kumkumadi_main{ margin-bottom: 0;background-image: url(/images/kumkumadi_day_bg.jpg);}
                    .kumkumadi_sec5 span{ display: table; margin: 0 auto;}
                    .btnbag_kumkumadi{right:0;    width: 100%;
                        bottom: 5px;
                        padding: 0 5px;}
        }
        .kumkumadi_sec5 span{ display: table; margin: 0 auto;}
        .kumkumadi_sec4 span{ display: table; margin: 0 auto;}
        .kumkumadi_sec4 span div{ display: table !important;}